import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Root } from './routes/Root';
import './index.css';
import { reportWebVitals } from './reportWebVitals';
import ReactGA from 'react-ga4';
import { AnalyticsWithConsent } from './components/main/AnalyticsWithConsent';
import { Navigation } from './navigation/Navigation';
import { paths } from './navigation/paths';
import { Home } from './routes/Home';
import { SearchResultPage } from './routes/SearchResultPage';
import { About } from './routes/About';
import { RegistrationDetails } from './routes/RegistrationDetails';
import { Contact } from './routes/Contact';
import { PrivatePolicy } from './routes/PrivatePolicy';
import { AdminPage } from './pages/Admin';
import { EditCategories } from './components/admin/categories/EditCategories';
import { EditDataAttributes } from './components/admin/data-attributes/EditDataAttributes';
import { EditFinancions } from './components/admin/financings/EditFinancings';
import { FortnoxStatus } from './components/admin/fortnox/FortnoxStatus';
import { EditCompanies } from './components/admin/owners/companies/EditCompanies';
import { EditOwners } from './components/admin/owners/EditOwners';
import { EditPersons } from './components/admin/owners/persons/EditPersons';
import { EditOwnership } from './components/admin/ownership/EditOwnership';
import { EditRegistrations } from './components/admin/registrations/EditRegistrations';
import { NewAdminRegistration } from './components/admin/registrations/NewAdminRegistration';
import { EditUsers } from './components/admin/users/EditUsers';

const router = createBrowserRouter([
  {
    path: paths.home.path,
    element: <Root />,
    children: [
      { index: true, element: <Home /> },
      { path: paths.search.path, element: <SearchResultPage /> },
      { path: paths.registrationDetails.path, element: <RegistrationDetails /> },
      { path: paths.registrationDetailsParent.path, element: <RegistrationDetails /> },
      { path: paths.about.path, element: <About /> },
      { path: paths.contact.path, element: <Contact /> },
      { path: paths.privatePolicy.path, element: <PrivatePolicy /> },
      { path: paths.registrationDetailsFromQr.path, element: <SearchResultPage /> },
      {
        path: '/admin',
        element: <AdminPage />,
        children: [
          { path: paths.editDataAttributes.path, element: <EditDataAttributes /> },
          { path: paths.editCategories.path, element: <EditCategories /> },
          { path: paths.editCompanies.path, element: <EditCompanies /> },
          { path: paths.editRegistrations.path, element: <EditRegistrations /> },
          { path: paths.newRegistration.path, element: <NewAdminRegistration /> },
          { path: paths.editPersons.path, element: <EditPersons /> },
          { path: paths.editOwners.path, element: <EditOwners /> },
          { path: paths.editUsers.path, element: <EditUsers /> },
          { path: paths.fortnox.path, element: <FortnoxStatus /> },
          { path: paths.editOwnership.path, element: <EditOwnership /> },
          { path: paths.editFinancions.path, element: <EditFinancions /> },
        ],
      },
      { path: '*', element: <Navigation /> },
    ],
  },
  // { path: '*', element: <Root /> },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <AnalyticsWithConsent />
  </React.StrictMode>
);

function sendToAnalytics({ id, name, value }: { id: string; name: string; value: number }) {
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    label: id, // id unique to current page load
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    nonInteraction: true, // avoids affecting bounce rate
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);
