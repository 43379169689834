import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { paths } from '../navigation/paths';
import { useUser } from '../context/UserProvider';
import { Roles } from '../auth/roles';

const AdminPageWrapper = styled.div``;

const AdminContent = styled.div`
  padding: 10px;

  @media (min-width: 600px) {
    flex: 1;
    padding: 20px;
    margin-left: 210px;
  }
`;

const AdminMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  a {
    color: #000;
    text-decoration: none;
    font-size: 0.9rem;
    padding: 5px;
  }

  @media (min-width: 600px) {
    padding-left: 0;
    padding-right: 0;
    display: block;
    width: 200px;
    background-color: #fff;
    border-right: 1px solid #eee;
    background-color: rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 60px;
    padding-top: 10px;
    height: calc(100vh - 60px);

    a {
      font-size: 1rem;
      padding: 6px 16px;
    }
  }
`;

const AdminMenu = () => {
  return (
    <AdminMenuWrapper>
      <MenuItem component={Link} to={paths.editFinancions.path}>
        Finansieringar
      </MenuItem>
      <MenuItem component={Link} to={paths.editRegistrations.path}>
        Registreringar
      </MenuItem>

      <MenuItem component={Link} to={paths.editDataAttributes.path}>
        Attribut
      </MenuItem>
      <MenuItem component={Link} to={paths.editCategories.path}>
        Kategorier
      </MenuItem>
      <MenuItem component={Link} to={paths.editOwners.path}>
        Ägare
      </MenuItem>
      <MenuItem component={Link} to={paths.editUsers.path}>
        Användare
      </MenuItem>
      <MenuItem component={Link} to={paths.fortnox.path}>
        Fortnox
      </MenuItem>
    </AdminMenuWrapper>
  );
};

export const AdminPage = () => {
  const { isAuthenticated, userProfile } = useUser();

  const isAdmin = (isAuthenticated && userProfile?.roles.includes(Roles.Admin)) ?? false;

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  return (
    <AdminPageWrapper>
      <AdminMenu />
      <AdminContent>
        <Outlet />
      </AdminContent>
    </AdminPageWrapper>
  );
};
