import { Route, Routes } from 'react-router-dom';
import { AuthRoutes } from './AuthRoutes';
import { ErrorRoutes } from './ErrorRoutes';
import { ProtectedRoutes } from './ProtectedRoutes';

export const Navigation = () => {
  return (
    <Routes>
      <Route>
        {ProtectedRoutes()}
        {AuthRoutes()}
        {ErrorRoutes()}
      </Route>
    </Routes>
  );
};
